import {DateTime} from "luxon";
import {Auction_ModelTypeDef} from "@nextlot/core/type_defs/model";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {WEBCAST_ENTER_TIMEFRAME_MS} from "@nextlot/core/hooks/LotHooks";


const WEBCAST_ENTER_BTN_LABELS = {
  enter: 'Enter webcast',
  view: 'View only',
}

export function WebcastEnterButtons({
  auction,
  isAnimated = false
}:{
  auction: Auction_ModelTypeDef,
  isAnimated?: boolean
}) {
    const timeNow = DateTime.now();

    if (auction.webcastIsPrepared && timeNow > auction.webcastStartsAt.minus(WEBCAST_ENTER_TIMEFRAME_MS)) {
        const enterWebcastBidderUrl = `${NextLotJSDATA.webapp_root_base_url}/webcast/${auction.id}/live`
        const enterWebcastViewerUrl = `${NextLotJSDATA.webapp_root_base_url}/webcast/${auction.id}/live?role=viewer`

        const buttonUrl = auction.myBiddingWarrantState ? enterWebcastBidderUrl : enterWebcastViewerUrl
        const buttonLabel = auction.myBiddingWarrantState ? ( auction.myBiddingWarrantState?.isAllowed ? WEBCAST_ENTER_BTN_LABELS.enter
                                                          : WEBCAST_ENTER_BTN_LABELS.view) : WEBCAST_ENTER_BTN_LABELS.view

        if (isAnimated) {
            return (
                <a
                    className="btn btn-outline-info btn-pulse-animation btn-border-animation"
                    href={buttonUrl}
                    target='_blank'
                >
                    {buttonLabel}

                    <span className="border-left-to-right"></span>
                    <span className="border-top-to-bottom"></span>
                    <span className="border-right-to-left"></span>
                    <span className="border-bottom-to-top"></span>
                </a>
            );
        }

        return <a className="btn btn-outline-secondary" href={buttonUrl} target='_blank'>{buttonLabel}</a>
    }

    return null
}
