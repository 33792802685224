import React, {PropsWithChildren} from "react";
import {Link} from "react-router-dom";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {Auction_ModelTypeDef, BiddingWarrantState_TypeDef} from "@nextlot/core/type_defs/model";
import ReactModal from "react-modal";
import {AppController, TaggedLogger} from "@nextlot/core";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "@nextlot/core/rematch/store";



const _logger = TaggedLogger.get('MyAuctionNotifications');


type CmpProps = {
    auction: Auction_ModelTypeDef
}
export function MyAuctionNotifications(props:PropsWithChildren<CmpProps>) {
    if (! NextLotJSDATA.site_notification_sms_auction_timeline_enabled) {
        return null;
    }

    const auction:Auction_ModelTypeDef = props.auction;
    const auctionId: number = auction.id;

    if (! auctionId || (!auction.timelineState.isUpcoming && !auction.timelineState.isRunning)) {
        return null;
    }


    const myBiddingWarrantState: BiddingWarrantState_TypeDef|null = auction.myBiddingWarrantState;
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isNotificationSmsAuctionTimelineEnabled, setIsNotificationSmsAuctionTimelineEnabled] = React.useState(null);
    const dispatch = useDispatch<Dispatch>();


    if(!myBiddingWarrantState) {
        return null;
    }


    React.useEffect(() => {
        setIsNotificationSmsAuctionTimelineEnabled(auction.notificationSmsAuctionTimelineEnabled);
    }, [auction.notificationSmsAuctionTimelineEnabled])


    const handleSubmit = async () => {
        try {
            const response = await AppController.instance.remoteDataHandler.updateAuctionTimelineNotification(auction.id, !isNotificationSmsAuctionTimelineEnabled);
            setIsNotificationSmsAuctionTimelineEnabled(response.isNotificationSmsAuctionTimelineEnabled);
            setIsModalOpen(false);

            // reload auction to refresh the new auction.notificationSmsAuctionTimelineEnabled state
            dispatch.auctionsLists.reloadAuction({ auctionId: auctionId });
        }
        catch (ex) {
            _logger.error(ex.toString());
        }
    }


    let modalBtnText = null;
    if (!NextLotJSDATA.bidder_phone_number_may_receive_sms) {
        modalBtnText = "Enable SMS notifications";
    }
    else if(isNotificationSmsAuctionTimelineEnabled) {
        modalBtnText = "Disable";
    } else {
        modalBtnText = "Enable"
    }



    if (myBiddingWarrantState.isAllowed || myBiddingWarrantState.isPendingResponse) {
        return (
            <>
                <ReactModal
                    isOpen={isModalOpen}
                    onRequestClose={() => (setIsModalOpen(false))}
                    contentLabel="Upcoming auction notifications"

                    portalClassName={'rmodal__portal'}
                    overlayClassName={{ base: 'rmodal__overlay', afterOpen: 'rmodal__overlay--after-open', beforeClose: '' }}
                    className={'rmodal__dialog'}
                    bodyOpenClassName={'rmodal__html-body--open'}
                    style={{
                        content: {
                            maxWidth: '500px',
                            height: 'auto',
                            borderRadius: "6px"
                        },
                    }}
                >
                    <div className="d-flex justify-content-between mx-3 mt-3">
                        <h5 className="">Upcoming auction SMS notifications</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => (setIsModalOpen(false))} />
                    </div>
                    <hr />

                    <div className="alert alert-light mx-3" role="alert">
                    {
                        NextLotJSDATA.bidder_phone_number_may_receive_sms ?
                            <>
                            {
                                isNotificationSmsAuctionTimelineEnabled ?
                                    <span>Upcoming auction SMS notifications are <strong>enabled</strong></span>
                                    :
                                    <span>Upcoming auction SMS notifications are <strong>disabled</strong></span>
                            }
                            </> :
                            <div>
                                Enable SMS notifications for your phone number.
                            </div>
                    }
                    </div>

                    <hr />
                    <div className="d-flex justify-content-end mx-3 mb-3 gap-2 row">
                        <button type="button" className="btn btn-secondary col-lg-auto" onClick={() => (setIsModalOpen(false))}>Close</button>
                        {
                            NextLotJSDATA.bidder_phone_number_may_receive_sms ?
                                <a className="btn btn-primary col-lg-auto" onClick={handleSubmit} >
                                    {`${modalBtnText}`}
                                </a>
                                :
                                <a className="btn btn-primary col-lg-auto" href={`${NextLotJSDATA.webapp_root_base_url}/account/contact_phone`} >
                                    {`${modalBtnText}`}
                                </a>
                        }

                    </div>

                </ReactModal>

                <button type="button" onClick={() => (setIsModalOpen(true))} className="btn btn-outline-secondary">
                    {
                        (isNotificationSmsAuctionTimelineEnabled && NextLotJSDATA.bidder_phone_number_may_receive_sms) ?
                            <i className="fa-solid fa-bell-ring fa-fw" />
                            :
                            <i className="fa-solid fa-bell-slash fa-fw" />
                    }
                </button>
            </>

        )
    }
}
