import React from "react";
import {Link, Navigate} from "react-router-dom";
import RoutesPathDefs from "../RoutesPathDefs";
import {TaggedLogger} from "@nextlot/core/utilities";
import {AppController} from "@nextlot/core";
import getCurrentBidder from "@nextlot/core/hooks/getCurrentBidder";
import {Auction_ModelTypeDef} from "@nextlot/core/type_defs/model";
import {useCurrentAuction} from "@nextlot/core/hooks/CurrentAuctionHooks";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";

const _logger = TaggedLogger.get('AuctionWarrantPageDispatcher');


export default function AuctionWarrantPageDispatcher(props) {
    _logger.debug('.render: ', props);

    const auction = useCurrentAuction();

    const currentBidder = getCurrentBidder({ onNotAuthenticated: () => {
            window.location.href = NextLotJSDATA.webapp_bidder_login_url;
        }});

    if (! currentBidder || ! auction.myBiddingWarrantState) {
        return <ErrorPage errorMessage={'Please register or login.'} />
    }

    if (auction.myBiddingWarrantState.hasRequiredBidderAction) {
        return <AuctionTermsViewForAccept auction={auction}/>
    }

    return <Navigate to={RoutesPathDefs.toAuctionLotsPage.generatePath(auction.id)} />;
}

function ErrorPage({errorMessage}:{errorMessage:string}) {
    return (
        <div className="py-5">
            <div className="alert alert-danger">
                {errorMessage}
            </div>

            <Link to={RoutesPathDefs.toHomePage.generatePath()}>Back to Auction</Link>
        </div>
    )
}


function AuctionTermsViewForAccept(props:{auction:Auction_ModelTypeDef}) {
    const {auction} = props;

    const [isChecked, setChecked] = React.useState(false);
    const [isSubmitting, setSubmitting] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const [isNotificationSmsAuctionTimelineEnabled, setNotificationSmsAuctionTimelineEnabled] = React.useState(NextLotJSDATA.bidder_notification_sms_auction_timeline_enabled && NextLotJSDATA.site_notification_sms_auction_timeline_enabled);
    const [isAtnOptInSelected, setIsAtnOptInSelected] = React.useState(NextLotJSDATA.bidder_notification_sms_auction_timeline_enabled);

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        setSubmitting(true);
        try {
            await AppController.instance.remoteDataHandler.submitAcceptSaleTerms(auction.id, auction.myBiddingWarrantState.code, isNotificationSmsAuctionTimelineEnabled);
        }
        catch (ex) {
            setSubmitting(false);
            setErrorMessage(ex.toString());
        }

        // DO NOT trigger navigation at this point here!
        // if everything is OK server-side, then Pusher will send an event, which will trigger the rendering with the new updated biddingWarrantState
    }


    return (
        <div className="container-xxl">
            <div className="row">
                <div className="col">

                    <div className="mt-5 py-5 border border-dark">
                        <iframe src={`${NextLotJSDATA.webapp_root_base_url}/sale_terms/${auction.id}`}
                                className='w-100' style={{ minHeight: '50vh'}}

                        />
                    </div>


                    <form onSubmit={handleSubmit}>
                        <div className='alert alert-warning fw-bold'>
                            {
                                auction.bidRequestNoteText &&
                                <div className='alert alert-info'>{auction.bidRequestNoteText}</div>
                            }

                            {
                                (NextLotJSDATA.site_notification_sms_auction_timeline_enabled && (auction.timelineState.isUpcoming || auction.timelineState.isRunning)) &&
                                <>
                                    {
                                        NextLotJSDATA.bidder_phone_number_may_receive_sms ?
                                            <>
                                                <span>Receive Upcoming Auction SMS Notifications?</span>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="atn" id="atnYes" value="yes"
                                                           checked={isNotificationSmsAuctionTimelineEnabled}
                                                           onChange={() => { setNotificationSmsAuctionTimelineEnabled(true); setIsAtnOptInSelected(true) }}/>
                                                    <label className="form-check-label" htmlFor="atnYes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="atn" id="atnNo" value="no"
                                                           onChange={() => { setNotificationSmsAuctionTimelineEnabled(false); setIsAtnOptInSelected(true) }} />
                                                    <label className="form-check-label" htmlFor="atnNo">
                                                        No
                                                    </label>
                                                </div>
                                            </>
                                             :
                                            <div>Receive Upcoming Auction SMS Notifications? (enable sms notifications for your phone number)</div>
                                    }
                                </>
                            }

                            <div className="form-check form-control-lg">
                                <label className="form-check-label">
                                    <input type="checkbox" className='form-check-input'
                                           disabled={isSubmitting}
                                           checked={isChecked} onChange={() => { setChecked(!isChecked) }}
                                           value={new Date().toString()} /> <strong>Accept Terms</strong> (check this box)
                                </label>
                            </div>

                            <div className='py-3'>
                                <button className='btn btn-primary' type='submit' disabled={
                                    ((auction.timelineState.isUpcoming || auction.timelineState.isRunning) && NextLotJSDATA.site_notification_sms_auction_timeline_enabled && NextLotJSDATA.bidder_phone_number_may_receive_sms)
                                        ?
                                        (!isChecked || isSubmitting || !isAtnOptInSelected)
                                        :
                                        (!isChecked || isSubmitting)}>Confirm Accept Terms
                                </button>
                            </div>

                            {
                                errorMessage &&
                                <div className='alert alert-danger'>
                                    <h4>Error!</h4>
                                    <p>{errorMessage}</p>
                                </div>
                            }
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
}

